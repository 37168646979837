import { Component } from 'react'
import cx from 'classnames'

import InputDropdown from 'BoComponents/generic/vanilla/InputDropdown.js'

import './AddressSearchInput.scss'

class AddressSearchInput extends Component {
  state = { searchResults: [], isEditing: false, temporarySearchValue: null }

  onChange = async (value) => {
    this.setState({ temporarySearchValue: value })

    if (!value) {
      this.setState({ searchResults: [] })
    } else {
      const results = await this.props.getAutocompleteResults(value)
      this.setState({ searchResults: results })
    }
  }

  onFocus = () => {
    this.setState({
      isEditing: true,
      temporarySearchValue: this.props.streetAddress,
    })
  }

  render() {
    const {
      className,
      streetAddress,
      onOutsideClickBlur,
      onSelectResult,
      isDisabled,
    } = this.props
    const { isEditing, temporarySearchValue } = this.state

    return (
      <InputDropdown
        placeholder="Street Address"
        isDisabled={isDisabled}
        className={cx('address-search-input', className)}
        value={isEditing ? temporarySearchValue : streetAddress}
        searchChoices={this.state.searchResults}
        onFocus={this.onFocus}
        onChange={this.onChange}
        onOutsideClickBlur={(query) => {
          if (query !== streetAddress) {
            onOutsideClickBlur(query)
          }
          this.setState({ isEditing: false })
        }}
        onSelectResult={(googleAddressResult) => {
          onSelectResult(googleAddressResult)
          this.setState({ isEditing: false })
        }}
      />
    )
  }
}
export default AddressSearchInput
