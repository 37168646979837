import { Component } from 'react'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'

import LeftContentRightPicture from 'ServiceRequestFlow/components/screens/templates/LeftContentRightPicture.js'
import {
  trackThumbtackShow,
  trackThumbtackClick,
} from 'ServiceRequestFlow/services/TrackingService.js'
import NextButton from './buttons/NextButton.js'

import './BudgetScreen.scss'

const GET_BUDGETS = gql`
  {
    budgets {
      id
      label
      display
    }
  }
`

class BudgetScreen extends Component {
  state = { budgetLabel: null, interestedInFinancing: null, tracked: false }

  thumbtackSlugForProjectType = (projectTypeId) => {
    const slugLookup = {
      88: 'gutters',
      92: 'finish-carpentry',
      93: 'stairs-and-railings',
      167: 'gazebos',
      46: 'drywall-repair',
      91: 'cabinet-installation',
      101: 'patios',
      73: 'garage-addition',
      12: 'bathroom-remodeling',
      42: 'countertop-installation',
      20: 'structural-engineers',
      40: 'fences',
      37: 'decks',
      21: 'roofing',
      36: 'interior-painting',
      53: 'concrete-contractors',
      28: 'hardwood-floor-installation',
      56: 'above-ground-pool-installers',
      32: 'vinyl-siding-repair',
      11: 'kitchen-remodeling',
      13: 'basements',
      39: 'framing-contractors',
      70: 'water-damage',
      38: 'door-repair',
      60: 'demolition-contractors',
    }

    return slugLookup[projectTypeId]
  }

  thumbtackUrl = (zipcode, projectTypeId) => {
    const params = '?utm_medium=partnership&utm_source=cma-buildzoom'
    if (this.thumbtackSlugForProjectType(projectTypeId)) {
      return `https://www.thumbtack.com/k/${this.thumbtackSlugForProjectType(
        projectTypeId
      )}
        /near-me/${params}&zip_code=
        ${zipcode}`
    } else {
      return undefined
    }
  }

  isForThumbtack = (projectTypeId) => {
    return this.thumbtackSlugForProjectType(projectTypeId) !== undefined
  }

  trackShowToThumbtack = (zipcode, projectTypeId) => {
    trackThumbtackShow({
      uuid: this.props.uuid,
      serviceRequestId: this.props.getServiceRequestId(),
      projectTypeId,
      zipcode,
    })
  }

  trackClickToThumbtack = (zipcode, projectTypeId) => {
    trackThumbtackClick({
      uuid: this.props.uuid,
      serviceRequestId: this.props.getServiceRequestId(),
      projectTypeId,
      zipcode,
    })
  }

  isLowBudget = () => {
    return this.state.budgetLabel && this.state.budgetLabel === '0-1000'
  }

  parseBudget = () => {
    let range = { min: null, max: null }

    if (this.state.budgetLabel) {
      let splitBudget = this.state.budgetLabel.split('-')

      if (splitBudget && splitBudget.length > 1) {
        range.min = parseInt(splitBudget[0], 10)
        range.max = parseInt(splitBudget[1], 10)
      }
    }

    return range
  }

  answersUrl = () => 'https://www.buildzoom.com/answers/'

  renderThumbtack = (zipcode, projectTypeId) => {
    this.trackShowToThumbtack(zipcode, projectTypeId)
    return (
      <div className="low-budget">
        Thanks for trusting BuildZoom to help make your project an amazing
        success! Because our focus is general construction projects, we've
        partnered with Thumbtack to help project owners just like you.
        <a
          target="_blank"
          onClick={() => this.trackClickToThumbtack(zipcode, projectTypeId)}
          href={this.thumbtackUrl(zipcode, projectTypeId)}
        >
          Click here to meet the best local pros for this project
        </a>
        .
      </div>
    )
  }

  renderLowBudget = () => {
    const zipcode = this.props.getUserInputs().zipcode?.value || ''
    const projectTypeId = this.props.getUserInputs().projectTypeId?.value

    if (this.isForThumbtack(projectTypeId)) {
      return this.renderThumbtack(zipcode, projectTypeId)
    } else {
      return (
        <div className="low-budget">
          Oops! We're sorry, we don't have any contractors that support projects
          under $1,000 in your area. Look for tips on getting your project
          started in our&nbsp;
          <a href={this.answersUrl()}>Q&A Forum</a>.
        </div>
      )
    }
  }

  render = () => {
    const { finish, recordUserInput, logo } = this.props
    const { budgetLabel, interestedInFinancing, tracked } = this.state
    return (
      <Query query={GET_BUDGETS}>
        {({ data: { budgets = [] } = {} }) => (
          <LeftContentRightPicture
            title="What is your budget?"
            className="budget-screen bg-white"
            helpText="Your budget estimate allows us to handle your project correctly. If you aren’t sure, just give us a rough estimate and we’ll help you fine-tune it later."
            logo={logo}
          >
            <select
              className="bg-white w-100"
              value={budgetLabel || ''}
              onChange={({ target }) =>
                this.setState({ budgetLabel: target.value })
              }
            >
              <option value={''} disabled>
                Select an option
              </option>
              {budgets.map(({ id, display, label }) => (
                <option key={id} value={label}>
                  {display}
                </option>
              ))}
            </select>
            {this.isLowBudget() && this.renderLowBudget()}

            <div className="budget-financing tooltip-container">
              <input
                type="checkbox"
                onChange={({ target }) =>
                  this.setState({ interestedInFinancing: target.checked })
                }
              />
              <span className="budget-financing-label">
                I'm interested in financing options
              </span>
              <span className="tooltip-text tooltip-text-bottom">
                We work closely with financial institutions to provide
                construction loans and mortgages if needed. They help many of
                our customers finance their projects.
              </span>
            </div>

            <NextButton
              onClick={() => {
                recordUserInput('budget', budgetLabel)
                recordUserInput('interestedInFinancing', interestedInFinancing)
                if (
                  !tracked &&
                  this.parseBudget().min >= 50000 &&
                  typeof fbq !== 'undefined'
                ) {
                  this.setState({ tracked: true })
                  fbq('trackCustom', 'HV SR Submission')
                }
                finish()
              }}
              disabled={this.isLowBudget()}
            />
          </LeftContentRightPicture>
        )}
      </Query>
    )
  }
}

export default BudgetScreen
