import { Component } from 'react'
import cx from 'classnames'
import LeftContentRightPicture from 'ServiceRequestFlow/components/screens/templates/LeftContentRightPicture.js'

import './DesignReadyScreen.scss'

class DesignReadyScreen extends Component {
  render = () => {
    const { finish, recordUserInput, designReady, logo } = this.props

    return (
      <LeftContentRightPicture
        title="Do you have completed designs?"
        className="design-ready-screen bg-white"
        logo={logo}
      >
        <div
          className={cx('option pointer flex items-center', {
            selected: designReady === 'finalized',
          })}
          onClick={() => {
            recordUserInput('designReady', 'finalized')
            finish()
          }}
        >
          <div className="circle mh3" />
          <div className="">Yes</div>
        </div>
        <div
          className={cx('option pointer flex items-center', {
            selected: designReady === 'in process',
          })}
          onClick={() => {
            recordUserInput('designReady', 'in process')
            finish()
          }}
        >
          <div className="circle mh3" />
          <div className="">In process with an architect</div>
        </div>
        <div
          className={cx('option pointer flex items-center', {
            selected: designReady === 'pre-design',
          })}
          onClick={() => {
            recordUserInput('designReady', 'pre-design')
            finish()
          }}
        >
          <div className="circle mh3" />
          <div className="">No</div>
        </div>
      </LeftContentRightPicture>
    )
  }
}

export default DesignReadyScreen
