import { Component } from 'react'
import cx from 'classnames'
import './LeftContentRightPicture.scss'

class LeftContentRightPicture extends Component {
  render = () => {
    const { className, title, children, hideImage, helpText, logo } = this.props

    let header
    if (helpText) {
      header = (
        <div className="title w-100">
          <span>{title}</span>
          <span className="pl3 tooltip-container">
            &#9432;
            <span className="tooltip-text tooltip-text-top">{helpText}</span>
          </span>
        </div>
      )
    } else {
      header = <div className="title w-100">{title}</div>
    }

    let rightPicture
    if (logo) {
      rightPicture = (
        <div className="bottom-right-logo">
          <img src={logo} />
        </div>
      )
    } else {
      rightPicture = (
        <div className="w-50 h-100 dn db-l">
          <div className={cx('bg-image w-100 h-100', { dn: hideImage })} />
        </div>
      )
    }

    return (
      <div
        className={cx(
          className,
          'left-content-right-picture w-100 h-100 flex justify-center pv4-ns ph5-ns'
        )}
      >
        <div className="w-50-l h-100 pt5 left-container relative ph3 overflow-y-auto ">
          {header}
          <div className="options-container">{children}</div>
        </div>
        {rightPicture}
      </div>
    )
  }
}

export default LeftContentRightPicture
