import { Component } from 'react'
import { flowRight as compose } from 'lodash'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import axios from 'axios'

import { pipe, prop, filter, keys, map, isEmpty } from 'ramda'

import {
  trackSaveProgress,
  trackUserInput,
  generateUUID,
} from 'ServiceRequestFlow/services/TrackingService.js'
/* eslint no-console: 0 */

const CREATE_PROJECT_SUBMISSION = gql`
  mutation CreateProjectSubmission(
    $inputs: ProjectSubmissionInputs!
    $flowUuid: String
  ) {
    createProjectSubmission(inputs: $inputs, flowUuid: $flowUuid) {
      errors {
        inputKey
        errors
      }
      serviceRequest {
        id
      }
      user {
        id
        phoneNumber
        firstName
        lastName
      }
      isUserSignedIn
      accessGrant
    }
  }
`

const SAVE_PROJECT_SUBMISSION = gql`
  mutation SaveProjectSubmission(
    $serviceRequestId: ID!
    $inputs: ProjectSubmissionInputs!
    $accessGrant: String
  ) {
    saveProjectSubmission(
      serviceRequestId: $serviceRequestId
      inputs: $inputs
      accessGrant: $accessGrant
    ) {
      errors {
        inputKey
        errors
      }
      serviceRequest {
        id
      }
      user {
        id
        phoneNumber
        name: fullname
      }
    }
  }
`

const GET_CURRENT_USER = `
  query getCurrentUser {
    me {
      id
      user {
        id
        phoneNumber
        name: fullname
      }
    }
  }
`

export const withProjectSubmissionAPI = () => (ChildComponent) => {
  class ProjectSubmissionAPI extends Component {
    constructor(props) {
      super(props)
      this.serviceRequestId = null
      this.isUserSignedIn = false
      this.userInputs = {}
      this.uuid = generateUUID()
      this.user = {}
    }

    componentDidMount = () => {
      let serverEndpoint
      if (
        window.location.hostname === 'www.buildzoom.com' ||
        window.location.hostname === 'dev.buildzoom.com'
      ) {
        serverEndpoint = '/graphql'
      } else {
        serverEndpoint = 'http://local.buildzoom.com:3000/graphql'
      }
      axios({
        url: serverEndpoint,
        method: 'post',
        data: {
          query: GET_CURRENT_USER,
        },
      }).then(
        ({
          data: {
            data: {
              me: { user },
            },
          },
        }) => {
          this.user = user
        }
      )
    }

    render = () => {
      const {
        createProjectSubmission,
        saveProjectSubmission,
        formVersion,
        source,
        originPage,
        ip,
        releaseVersion,
        landingPage,
        formData,
        queryParams,
        ...passThroughProps
      } = this.props
      return (
        <ChildComponent
          uuid={this.uuid}
          variant={this.variant}
          formVersion={formVersion}
          source={source}
          originPage={originPage}
          formData={formData}
          releaseVersion={releaseVersion}
          landingPage={landingPage}
          ip={ip}
          {...passThroughProps}
          projectSubmissionAPI={{
            getServiceRequestId: () => this.serviceRequestId,
            getIsUserSignedIn: () => this.isUserSignedIn,
            getUserInputs: () => this.userInputs,
            getUser: () => this.user,
            addUserInput: (key, value, source) => {
              this.userInputs[key] = { value, source, dirty: true }
              trackUserInput({
                value,
                source,
                formVersion,
                variant: formVersion,
                originPage,
                releaseVersion,
                landingPage,
                uuid: this.uuid,
                target: key,
              })
            },
            saveProgress: async () => {
              const relevantInputs = pipe(
                filter(prop('dirty')),
                map(prop('value'))
              )(this.userInputs)

              if (isEmpty(relevantInputs)) {
                return { errors: [], serviceRequestId: this.serviceRequestId }
              }

              let errors
              if (this.serviceRequestId) {
                const { data } = await saveProjectSubmission({
                  variables: {
                    serviceRequestId: this.serviceRequestId,
                    inputs: relevantInputs,
                    accessGrant: this.accessGrant,
                  },
                })

                this.user = data.saveProjectSubmission.user
                errors = data.saveProjectSubmission.errors
              } else {
                const { data } = await createProjectSubmission({
                  variables: {
                    inputs: {
                      ...relevantInputs,
                      formVersion: formVersion || this.variant,
                      referralSource: source,
                      originPage,
                      ip,
                      reftag: formData.reftag,
                      utmCampaign: queryParams.utm_campaign,
                      utmContent: queryParams.utm_content,
                      utmMedium: queryParams.utm_medium,
                      utmSource: queryParams.utm_source,
                      utmTerm: queryParams.utm_term,
                      variation: queryParams.variation,
                      landingPage,
                    },
                    flowUuid: this.uuid,
                  },
                })
                errors = data.createProjectSubmission.errors
                this.isUserSignedIn =
                  data.createProjectSubmission.isUserSignedIn
                this.user = data.createProjectSubmission.user
                if (data.createProjectSubmission.serviceRequest) {
                  this.serviceRequestId =
                    data.createProjectSubmission.serviceRequest.id
                  this.accessGrant = data.createProjectSubmission.accessGrant
                  if (
                    originPage.includes('www.buildzoom.com') &&
                    window.parent.gtag
                  ) {
                    const bzGlobal =
                      window.bzGlobal || window.parent.bzGlobal || {}
                    window.parent.gtag('event', 'sr_flow', {
                      action: 'submitted',
                      variant: this.variant,
                      label: 'v2',
                      ...(bzGlobal.baseAnalyticsParams || {}),
                    })
                  }
                }
              }

              if (errors.length === 0) {
                map((k) => {
                  this.userInputs[k].dirty = false
                })(keys(relevantInputs))
              }

              trackSaveProgress({
                target: this.serviceRequestId,
                isUserSignedIn: this.isUserSignedIn,
                relevantInputs: keys(relevantInputs),
                variant: formVersion,
                uuid: this.uuid,
                releaseVersion,
                landingPage,
                errors,
                formVersion,
                originPage,
              })

              return { errors, serviceRequestId: this.serviceRequestId }
            },
            getSourceForInput: (key) => this.userInputs[key].source, // todo: default?
            reset: () => {
              this.serviceRequestId = null
              this.userInputs = {}
            },
          }}
        />
      )
    }
  }

  return compose(
    graphql(CREATE_PROJECT_SUBMISSION, {
      name: 'createProjectSubmission',
    }),
    graphql(SAVE_PROJECT_SUBMISSION, {
      name: 'saveProjectSubmission',
    })
  )(ProjectSubmissionAPI)
}
