import { Component } from 'react'
import cx from 'classnames'

class ChatContainer extends Component {
  render = () => {
    const { children, isOpen, close } = this.props

    return (
      <div
        className={cx(
          'fixed bg-white justify-center overflow-hidden w-100 h-100',
          isOpen ? 'flex' : 'dn'
        )}
      >
        {children}
        <div
          className="absolute right-0 pa4 blue pointer"
          onClick={() => close()}
        >
          Close X
        </div>
      </div>
    )
  }
}

export default ChatContainer
