import './TermsOfService.scss'

const TERMS_LINK = 'https://www.buildzoom.com/terms-of-service'

const TermsOfService = ({ version }) => {
  if (version === 'txt') {
    return (
      <div className="terms-of-service">
        By clicking next, you agree to our&nbsp;
        <a href={TERMS_LINK} target="_blank">
          Terms of Service
        </a>
        , and authorize BuildZoom and its partners to send automated calls and
        text messages to the phone number provided above. Consent is not a
        condition of purchase.
      </div>
    )
  } else {
    return (
      <div className="terms-of-service">
        By clicking next, you agree to our&nbsp;
        <a href={TERMS_LINK} target="_blank">
          Terms of Service
        </a>
        .
      </div>
    )
  }
}

export default TermsOfService
