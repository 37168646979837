/* eslint no-console: 0 */
import ApolloClient from 'apollo-client'
import { withClientState } from 'apollo-link-state'
import { HttpLink } from 'apollo-link-http'
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
  defaultDataIdFromObject,
} from 'apollo-cache-inmemory'
import { onError } from 'apollo-link-error'
import introspectionQueryResultData from 'WebRoot/fragment_types.json'

// Enables fragments on interfaces and unions
// https://www.apollographql.com/docs/react/advanced/fragments.html
const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
})

const createApolloClient = (options = {}) => {
  const { clientDefaults, httpLinkOptions } = options
  const httpLink = new HttpLink(httpLinkOptions || { uri: '/graphql' })
  const errorLogger = onError(({ networkError }) => {
    if (
      networkError &&
      networkError.statusCode === 500 &&
      networkError.result
    ) {
      const { error, stacktrace } = networkError.result
      console.error(`${error}\n${stacktrace}`)
    }
  })

  const cache = new InMemoryCache({
    fragmentMatcher,
    cacheRedirects: {
      Query: {
        shortlistedContractors: (_, args, { getCacheKey }) =>
          args.ids.map((id) =>
            getCacheKey({ __typename: 'ShortlistedContractor', id })
          ),
      },
    },
    dataIdFromObject: (object) => {
      switch (object.__typename) {
        case 'DistributionListContractor':
          return `DistributionListContractor:${object.searchId}:${object.id}`
        default:
          return defaultDataIdFromObject(object) // fall back to default handling
      }
    },
  })

  const stateLink = withClientState({ cache, defaults: clientDefaults })

  return new ApolloClient({
    cache,
    link: errorLogger.concat(stateLink).concat(httpLink),
  })
}

export default createApolloClient
