import { Component } from 'react'
import cx from 'classnames'

import GoogleMapsApi from 'ServiceRequestFlow/services/GoogleMapsApi.js'
import LeftContentRightPicture from 'ServiceRequestFlow/components/screens/templates/LeftContentRightPicture.js'

import AddressSearchInput from '../AddressSearchInput.js'
import NextButton from './buttons/NextButton.js'

import './AddressScreen.scss'

class AddressScreen extends Component {
  state = {
    mapsApiLoaded: false,
    googleMapsApi: null,
    streetAddress: null,
    city: null,
    state: null,
    zipcode: null,
    lat: null,
    lng: null,
    clientError: '',
  }

  componentDidMount() {
    const api = new GoogleMapsApi(() => this.setState({ mapsApiLoaded: true }))
    this.setState({ googleMapsApi: api })
  }

  render = () => {
    const { logo } = this.props
    const { mapsApiLoaded, zipcode, clientError } = this.state

    return (
      <LeftContentRightPicture
        title="Where do you need a contractor?"
        className="address-screen bg-white"
        hideImage={true}
        logo={logo}
      >
        <div className="w-100 flex flex-wrap">
          <div className="w-100 w-70-ns pr2-ns">
            {mapsApiLoaded ? this.renderStreetAddressTypeahead() : null}
          </div>
          <input
            className={cx('input w-100 w-30-ns mt0-ns mt3', {
              error: clientError,
            })}
            type="text"
            value={zipcode || ''}
            onChange={({ target }) =>
              this.setState({
                zipcode:
                  target.value === ''
                    ? null
                    : target.value.replace(/\D/g, '').slice(0, 5),
                lat: null,
                lng: null,
              })
            }
            placeholder="Zip"
          />
        </div>
        <div className={cx('red dn mt3', { db: clientError })}>
          {clientError}
        </div>
        {mapsApiLoaded ? this.renderNextButton() : null}
      </LeftContentRightPicture>
    )
  }

  renderStreetAddressTypeahead = () => {
    const { googleMapsApi, streetAddress, zipcode } = this.state
    const { geocode, getAutocompleteResults } = googleMapsApi

    return (
      <AddressSearchInput
        getAutocompleteResults={(street) =>
          getAutocompleteResults(this.toQuery(street, zipcode))
        }
        isDisabled={false}
        streetAddress={streetAddress}
        zipcode={zipcode}
        onSelectResult={async (fullAddress) => {
          const result = await geocode(fullAddress)
          if (result) {
            this.setState({
              streetAddress: result.streetAddress,
              city: result.city,
              state: result.state,
              zipcode: result.zipcode,
              lat: result.lat,
              lng: result.lng,
            })
          }
        }}
        onOutsideClickBlur={(streetAddress) => {
          this.setState({ streetAddress, lat: null, lng: null })
        }}
      />
    )
  }

  // todo: should render a fallback next button while google maps loads
  // so that if google maps never loads/fails we can still move away from this screen
  renderNextButton = () => {
    const { finish, recordUserInput, saving } = this.props
    const { googleMapsApi, streetAddress, city, state, zipcode, lat, lng } =
      this.state
    const { geocode, isStreetAddressMatch } = googleMapsApi

    return (
      <NextButton
        className="mt4"
        saving={saving}
        onClick={async () => {
          if (!zipcode || zipcode.length !== 5) {
            this.setState({
              clientError:
                '*A zipcode is required to help us find relevant contractors.',
            })
            return
          }

          if (lat && lng) {
            recordUserInput('streetAddress', streetAddress)
            recordUserInput('city', city)
            recordUserInput('state', state)
            recordUserInput('zipcode', zipcode)
            recordUserInput('latitude', lat)
            recordUserInput('longitude', lng)
          } else {
            const result = await geocode(this.toQuery(streetAddress, zipcode))
            const acceptResult =
              result &&
              isStreetAddressMatch(result.streetAddress, streetAddress) &&
              (!zipcode || zipcode === result.zipcode)

            if (acceptResult) {
              recordUserInput('streetAddress', result.streetAddress)
              recordUserInput('city', result.city)
              recordUserInput('state', result.state)
              recordUserInput('zipcode', result.zipcode)
              recordUserInput('latitude', result.lat)
              recordUserInput('longitude', result.lng)

              this.setState({
                streetAddress: result.streetAddress,
                city: result.city,
                state: result.state,
                zipcode: result.zipcode,
                lat: result.lat,
                lng: result.lng,
              })
            } else {
              recordUserInput('streetAddress', streetAddress)
              recordUserInput('city', city)
              recordUserInput('state', state)
              recordUserInput('zipcode', zipcode)
              recordUserInput('latitude', null)
              recordUserInput('longitude', null)
              this.setState({ lat: null, lng: null })
            }
          }

          this.setState({ clientError: '' })
          finish(true)
        }}
      />
    )
  }

  toQuery = (streetAddress, zipcode) => {
    let query = streetAddress || ''
    if (zipcode) query = `${query} ${zipcode}`
    return query
  }
}

export default AddressScreen
