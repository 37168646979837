import { Component } from 'react'

import { openFilePicker } from 'ServiceRequestFlow/services/FilestackService.js'
import LeftContentRightPicture from 'ServiceRequestFlow/components/screens/templates/LeftContentRightPicture.js'
import NextButton from './buttons/NextButton.js'

import './DescriptionScreen.scss'

class FencingDescriptionScreen extends Component {
  state = { description: '', attachments: [] }

  render = () => {
    const { finish, recordUserInput, saving, logo } = this.props
    const { description, attachments } = this.state

    return (
      <LeftContentRightPicture
        title="Please give a brief description of your project."
        className="description-screen"
        hideImage={true}
        logo={logo}
      >
        <textarea
          className="input w-100 mb3"
          rows="10"
          value={description}
          onChange={(e) => this.setState({ description: e.target.value })}
          placeholder="Please tell us a bit more about the fence you want to install. Details such as the area you want to fence will be helpful! Also if you have some, upload pictures of your existing fence and any fence designs that have caught your eye!"
        />
        {attachments.length > 0 ? this.renderAttachments() : null}
        <div
          className="file-attachment-container flex justify-center items-center bz-blue pointer w-90"
          onClick={() => {
            openFilePicker(({ filesUploaded }) => {
              this.setState({
                attachments: [
                  ...attachments,
                  ...filesUploaded.map(({ key, filename, url, mimetype }) => ({
                    name: filename,
                    filename,
                    filepickerSlug: url,
                    file: true,
                    description: mimetype,
                    s3Slug: key,
                  })),
                ],
              })
            })
          }}
        >
          <div className="f6">
            + Attach any plans, photos, etc of the project
          </div>
        </div>
        <NextButton
          className="mt4"
          saving={saving}
          onClick={() => {
            recordUserInput('description', this.state.description)
            recordUserInput('attachments', this.state.attachments)
            finish(true)
          }}
        />
      </LeftContentRightPicture>
    )
  }

  renderAttachments = () => {
    const { attachments } = this.state

    return (
      <div className="mb3 pa3 uploaded-attachments-container">
        <div className="f5 bz-blue fw6">Uploaded files:</div>
        <div className="flex flex-wrap mt3">
          {attachments.map(({ filename }) => (
            <div key={filename} className="mr3">{`${filename}`}</div>
          ))}
        </div>
      </div>
    )
  }
}

export default FencingDescriptionScreen
