import { Component } from 'react'
import LeftContentRightPicture from 'ServiceRequestFlow/components/screens/templates/LeftContentRightPicture.js'
import NextButton from './buttons/NextButton.js'

import './NameScreen.scss'

class NameScreen extends Component {
  state = { name: '' }

  render = () => {
    const { finish, recordUserInput, logo } = this.props
    const { name } = this.state

    return (
      <LeftContentRightPicture
        title="Tell us your name."
        className="name-screen"
        hideImage={true}
        logo={logo}
      >
        <input
          className="input w-100"
          type="text"
          value={name}
          name="name"
          autoComplete="name"
          onChange={(e) => this.setState({ name: e.target.value })}
          placeholder="Type your name"
          onKeyPress={({ key }) => {
            if (key === 'Enter') {
              recordUserInput('name', this.state.name)
              finish()
            }
          }}
        />
        <NextButton
          className="mt4"
          onClick={() => {
            recordUserInput('name', this.state.name)
            finish()
          }}
        />
      </LeftContentRightPicture>
    )
  }
}

export default NameScreen
