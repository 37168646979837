import { Component } from 'react'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import { pipe, propEq, map, find, propOr } from 'ramda'
import cx from 'classnames'

import SearchableDropdown from 'BoComponents/generic/vanilla/SearchableDropdown.js'

import './TitleScreen.scss'

const GET_PROJECT_TYPES = gql`
  {
    projectTypes {
      id
      name
    }
  }
`

const DEFAULT_PROJECT_TYPES = [
  { value: '1', label: 'Custom Home Build' },
  { value: '17', label: 'Office Renovation' },
  { value: '27', label: 'Solar Installation' },
  { value: '18', label: 'Restaurant Renovation' },
  { value: '11', label: 'Kitchen Remodel' },
  { value: '13', label: 'Bathroom Remodel' },
  { value: '122', label: 'Whole Home Remodel' },
  { value: '15', label: 'Medium Multi-Family Remodel (5-25 Units)' },
  { value: '26', label: 'Electrical Work' },
  { value: '28', label: 'Hardwood Flooring' },
]

class TitleScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      title: props.formData['service_request[title]'] || '',
      projectTypeId: null,
      clientError: '',
    }
  }

  render = () => {
    const { finish, recordUserInput, errors, popularProjects } = this.props
    const { title, projectTypeId, clientError } = this.state

    return (
      <Query query={GET_PROJECT_TYPES}>
        {({ data: { projectTypes = [] } = {} }) => (
          <div className="title-screen w-100 h-100 flex flex-column justify-center-ns items-center overflow-y-auto bg-white">
            <div className="container mh3">
              <div className="title mt5 mt3-ns">
                What do you need help with?
              </div>
              <SearchableDropdown
                className={cx({ error: clientError || errors.length })}
                unfocusedValue={title}
                searchChoices={map(
                  ({ id, name }) => ({ value: id, label: name }),
                  projectTypes
                )}
                placeholder="ex. remodel, custom home, etc"
                onSelectResult={(id) => {
                  this.setState({
                    projectTypeId: id,
                    title: pipe(
                      find(propEq('id', id)),
                      propOr(null, 'name')
                    )(projectTypes),
                  })
                }}
                onOutsideClickBlur={(v) => {
                  this.setState({ title: v, projectTypeId: null })
                }}
                disableFuzzyMatching={true}
                defaultDropdownOptions={DEFAULT_PROJECT_TYPES}
              />
              <div
                className={cx('red dn mb2', {
                  db: clientError || errors.length,
                })}
              >
                {clientError || errors[0]}
              </div>
              <div
                className="next w-100 bg-bz-blue white pointer mb4 mb5-ns flex justify-center items-center"
                onClick={() => {
                  if (!title && !projectTypeId) {
                    this.setState({
                      clientError:
                        '*Please enter what type of service you need.',
                    })
                    return
                  }

                  if (projectTypeId) {
                    recordUserInput('title', null)
                    recordUserInput('projectTypeId', projectTypeId)
                  } else {
                    recordUserInput('title', title)
                    recordUserInput('projectTypeId', projectTypeId)
                  }

                  this.setState({ clientError: '' })
                  finish()
                }}
              >
                Next
              </div>
              <div className="w-100 tc mb2 f6 f5-ns">Popular projects:</div>
              <div className="w-100 flex flex-wrap mb4">
                {popularProjects.map(({ id, name }) => (
                  <div key={name} className="w-50 tc pa1">
                    <div
                      className="popular-project-button pointer flex items-center justify-center"
                      onClick={() => {
                        this.setState({
                          title: name,
                          projectTypeId: id,
                          clientError: '',
                        })
                        recordUserInput('title', name)
                        recordUserInput('projectTypeId', id)
                        finish()
                      }}
                    >
                      <div>{name}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </Query>
    )
  }
}

export default TitleScreen
