import { Component } from 'react'
import cx from 'classnames'

import { validateEmail } from 'ServiceRequestFlow/helpers/email.js'
import LeftContentRightPicture from 'ServiceRequestFlow/components/screens/templates/LeftContentRightPicture.js'
import TermsOfService from 'ServiceRequestFlow/components/TermsOfService.js'
import NextButton from './buttons/NextButton.js'

import './EmailScreen.scss'
import '../TermsOfService.scss'

class EmailScreen extends Component {
  state = { email: '', clientError: '' }
  render = () => {
    const { saving, errors, logo, tosScreen } = this.props
    const { email, clientError } = this.state
    const showTos = tosScreen !== 'PhoneNumberScreen'

    return (
      <LeftContentRightPicture
        title="What's your email address?"
        className="email-screen"
        hideImage={true}
        logo={logo}
      >
        <input
          className={cx('input w-100', { error: clientError })}
          type="email"
          name="email"
          autoComplete="email"
          value={email}
          onChange={(e) => this.setState({ email: e.target.value })}
          placeholder="Email Address"
          onKeyPress={({ key }) => {
            if (key === 'Enter' && !saving) {
              this.submit()
            }
          }}
        />
        <div className={cx('red dn mt3', { db: clientError || errors.length })}>
          {clientError || errors[0]}
        </div>
        <NextButton className="mt4" saving={saving} onClick={this.submit} />
        {showTos && <TermsOfService version="txt" />}
      </LeftContentRightPicture>
    )
  }

  submit = () => {
    const { finish, recordUserInput } = this.props
    const { email } = this.state

    if (validateEmail(email)) {
      recordUserInput('email', email)
      this.setState({ clientError: '' })
      finish(true)
    } else {
      this.setState({ clientError: '*Please enter a valid email address.' })
    }
  }
}

export default EmailScreen
