import { pipe } from 'ramda'
import KeyFormatConverter from 'Vanilla/services/key_format_converter'

const GOOGLE_MAPS_API_URL =
  'https://maps.googleapis.com/maps/api/js?v=3.52.1&libraries=places&language=en&key='
const GOOGLE_BROSWER_KEY = 'AIzaSyCWf9b01bqkaBCgsGZe4Di-20w50cJp4oQ'

let AutocompleteService = null
let Geocoder = null

let isLoadingScript = false
let onLoadCallbacks = []

const addGoogleMapsApiScript = (callback) => {
  isLoadingScript = true
  const script = document.createElement('script')
  script.async = true
  script.src = `${GOOGLE_MAPS_API_URL}${GOOGLE_BROSWER_KEY}`
  document.head.appendChild(script)
  script.addEventListener('load', () => {
    AutocompleteService = new window.google.maps.places.AutocompleteService()
    Geocoder = new window.google.maps.Geocoder()
    callback()
    isLoadingScript = false
  })
}

class GoogleMapsApi {
  constructor(onScriptLoaded) {
    if (window.google) {
      onScriptLoaded()
    } else {
      onLoadCallbacks.push(onScriptLoaded)

      if (!isLoadingScript) {
        addGoogleMapsApiScript(() => {
          onLoadCallbacks.map((cb) => cb())
        })
      }
    }
  }

  getAutocompleteResults = (query) =>
    new Promise((resolve) => {
      AutocompleteService.getPlacePredictions(
        {
          input: query,
          types: ['geocode'],
          componentRestrictions: { country: 'us' },
        },
        (predictions, status) => {
          if (status === 'OK') {
            const addresses = predictions.map(({ description }) => ({
              value: description,
              label: description,
            }))
            resolve(addresses)
          }
        }
      )
    })

  geocode = (query) =>
    new Promise((resolve) => {
      Geocoder.geocode(
        { address: query },
        pipe(
          parseGoogleLocationApiDetails,
          KeyFormatConverter.mutateToCamel,
          resolve
        )
      )
    })

  isStreetAddressMatch(queryStreet, resultStreet) {
    let isMatch = false

    let isExactMatch = queryStreet === resultStreet
    let isFalsyMatch =
      (queryStreet === '' ||
        queryStreet === null ||
        queryStreet === undefined) &&
      (resultStreet === '' ||
        resultStreet === null ||
        resultStreet === undefined)

    if (isExactMatch || isFalsyMatch) {
      isMatch = true
    } else if (
      typeof queryStreet === 'string' &&
      typeof resultStreet === 'string' &&
      resultStreet.length > 0
    ) {
      let qAsLowercase = queryStreet.toLowerCase()
      let rAsLowercase = resultStreet.toLowerCase()

      let editDistance = getEditDistance(qAsLowercase, rAsLowercase)
      let maxLetters = Math.max(qAsLowercase.length, rAsLowercase.length)
      let editDistancePercentage = (editDistance / maxLetters) * 100

      isMatch = editDistancePercentage < 30
    }

    return isMatch
  }
}

const parseGoogleLocationApiDetails = (results) => {
  if (results === undefined || results[0] === undefined) {
    return null
  }
  let response = {
    neighborhood: '',
    street_number: '',
    street_name: '',
    street_address: '',
    city: '',
    state: '',
    zipcode: '',
    lat: '',
    lng: '',
    county: '',
  }
  let details = results[0]

  for (let i = 0; i < details.address_components.length; i++) {
    switch (details.address_components[i].types[0]) {
      case 'neighborhood':
        response.neighborhood = details.address_components[i].long_name
        break
      case 'street_number':
        response.street_number = details.address_components[i].long_name
        break
      case 'route':
        response.street_name = details.address_components[i].short_name
        break
      case 'postal_code':
        response.zipcode = details.address_components[i].long_name
        break
      case 'sublocality_level_1':
        response.city = details.address_components[i].long_name
        break
      case 'locality':
      case 'political':
        if (!response.city || !response.city.length) {
          response.city = details.address_components[i].long_name
        }
        break
      case 'administrative_area_level_1':
        response.state = details.address_components[i].short_name
        break
      case 'administrative_area_level_2':
        response.county = details.address_components[i].long_name
        break
      case 'country':
        response.country = details.address_components[i].long_name
        break
      default:
        break
    }
  }

  // special case for neighborhood with lots of contractors
  if (
    response.city === 'Los Angeles' &&
    response.neighborhood === 'Woodland Hills'
  ) {
    response.city = response.neighborhood
  }

  // special case for Bronx, NY (Google API doesn't find as city)
  if (response.county === 'Bronx County') {
    response.city = 'Bronx'
  }

  if (details.geometry.location.lat() && details.geometry.location.lng()) {
    response.lat = details.geometry.location.lat()
    response.lng = details.geometry.location.lng()
  } else if (details.geometry.location.A && details.geometry.location.F) {
    response.lat = details.geometry.location.A
    response.lng = details.geometry.location.F
  } else if (details.geometry.location.G && details.geometry.location.K) {
    response.lat = details.geometry.location.G
    response.lng = details.geometry.location.K
  }

  if (response.lat && response.lng) {
    response.coord = [response.lat, response.lng].join(',')
  }

  if (response.street_number) {
    response.street_address = response.street_number
  }

  if (response.street_name) {
    if (response.street_address && response.street_address.length) {
      response.street_address += ' '
    }
    response.street_address += response.street_name
  }

  if (response.street_number) {
    response.zoom = 18
  } else if (response.street_name || response.neighborhood) {
    response.zoom = 14
  } else {
    response.zoom = 12
  }

  if (details.formatted_address) {
    response.formatted_address = details.formatted_address
    response.alias = response.formatted_address
      .replace(/,+/g, ' ')
      .replace(/\s+/g, '-')
      .trim()
      .toLowerCase()
  }
  return response
}

function getEditDistance(a, b) {
  // gist from https://gist.github.com/andrei-m/982927
  if (a.length === 0) return b.length
  if (b.length === 0) return a.length

  let matrix = []

  for (let i = 0; i <= b.length; i++) {
    matrix[i] = [i]
  }
  for (let j = 0; j <= a.length; j++) {
    matrix[0][j] = j
  }

  for (let i = 1; i <= b.length; i++) {
    // Fill in the rest of the matrix
    for (let j = 1; j <= a.length; j++) {
      if (b.charAt(i - 1) === a.charAt(j - 1)) {
        matrix[i][j] = matrix[i - 1][j - 1]
      } else {
        matrix[i][j] = Math.min(
          matrix[i - 1][j - 1] + 1, // substitution
          Math.min(
            matrix[i][j - 1] + 1, // insertion
            matrix[i - 1][j] + 1
          ) // deletion
        )
      }
    }
  }

  return matrix[b.length][a.length]
}

export default GoogleMapsApi
