/* global document, module */
import 'tachyons'
import ReactDOM from 'react-dom'
import ErrorBoundary from 'GlobalShared/clients/srFlowBugsnagClient'
import App from './app.js'

const elToBindTo = document.getElementById('service-request-flow')

const render = () =>
  ReactDOM.render(
    <ErrorBoundary>
      <App {...elToBindTo.dataset} />
    </ErrorBoundary>,
    elToBindTo
  )

render()

if (module.hot) {
  module.hot.accept('ServiceRequestFlow/app', render)
}
