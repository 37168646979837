import { Component } from 'react'
import cx from 'classnames'
import LeftContentRightPicture from 'ServiceRequestFlow/components/screens/templates/LeftContentRightPicture.js'

import './NumberOfBidsScreen.scss'

class NumberOfBidsScreen extends Component {
  render = () => {
    const { finish, recordUserInput, numberOfBids, logo } = this.props

    return (
      <LeftContentRightPicture
        title="How many bids do you want from contractors?"
        className="number-of-bids-screen"
        hideImage={true}
        logo={logo}
      >
        <div
          className={cx('option pointer flex items-center', {
            selected: numberOfBids === 3,
          })}
          onClick={() => {
            recordUserInput('numberOfBids', 3)
            finish()
          }}
        >
          <div className="circle mh3" />
          <div className="">3</div>
        </div>
        <div
          className={cx('option pointer flex items-center', {
            selected: numberOfBids === 4,
          })}
          onClick={() => {
            recordUserInput('numberOfBids', 4)
            finish()
          }}
        >
          <div className="circle mh3" />
          <div className="">4 (Recommended)</div>
        </div>
        <div
          className={cx('option pointer flex items-center', {
            selected: numberOfBids === 5,
          })}
          onClick={() => {
            recordUserInput('numberOfBids', 5)
            finish()
          }}
        >
          <div className="circle mh3" />
          <div className="">5</div>
        </div>
      </LeftContentRightPicture>
    )
  }
}

export default NumberOfBidsScreen
