import { Component } from 'react'
import cx from 'classnames'
import LeftContentRightPicture from 'ServiceRequestFlow/components/screens/templates/LeftContentRightPicture.js'

import './PropertyTypeScreen.scss'

class PropertyTypeScreen extends Component {
  render = () => {
    const { finish, recordUserInput, propertyType, logo } = this.props

    return (
      <LeftContentRightPicture
        title="What type of property is this?"
        className="property-type-screen bg-white"
        logo={logo}
      >
        <div
          className={cx('option pointer flex items-center', {
            selected: propertyType === 'home',
          })}
          onClick={() => {
            recordUserInput('propertyType', 'home')
            finish()
          }}
        >
          <div className="circle mh3" />
          <div className="">Single Family Home</div>
        </div>
        <div
          className={cx('option pointer flex items-center', {
            selected: propertyType === 'condo',
          })}
          onClick={() => {
            recordUserInput('propertyType', 'condo')
            finish()
          }}
        >
          <div className="circle mh3" />
          <div className="">Condo / Apartment</div>
        </div>
        <div
          className={cx('option pointer flex items-center', {
            selected: propertyType === 'office',
          })}
          onClick={() => {
            recordUserInput('propertyType', 'office')
            finish()
          }}
        >
          <div className="circle mh3" />
          <div className="">Office / Commercial</div>
        </div>
        <div
          className={cx('option pointer flex items-center', {
            selected: propertyType === 'other',
          })}
          onClick={() => {
            recordUserInput('propertyType', 'other')
            finish()
          }}
        >
          <div className="circle mh3" />
          <div className="">Other</div>
        </div>
      </LeftContentRightPicture>
    )
  }
}

export default PropertyTypeScreen
