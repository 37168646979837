import { Component } from 'react'
import cx from 'classnames'

import './ModalContainer.scss'

class ModalContainer extends Component {
  handleClick = (e) => {
    const { close, isOpen } = this.props

    if (isOpen && !this.modalContentContainer.contains(e.target)) {
      close()
    }
  }

  componentDidMount = () =>
    document.addEventListener('mouseup', this.handleClick)

  componentWillUnmount = () =>
    document.removeEventListener('mouseup', this.handleClick)

  render = () => {
    const { children, isOpen } = this.props

    return (
      <div
        className={cx(
          'fixed modal-container flex-column justify-center items-center vh-100 top-0 left-0',
          isOpen ? 'flex' : 'dn'
        )}
      >
        <div
          className="content-container relative bg-white o-1 overflow-hidden"
          ref={(n) => {
            this.modalContentContainer = n
          }}
        >
          {children}
        </div>
      </div>
    )
  }
}

export default ModalContainer
