import { Component } from 'react'
import cx from 'classnames'
import { pipe, map, reverse, last, mapObjIndexed, values } from 'ramda'

import {
  getScreenConfig,
  getFirstScreenKey,
} from 'ServiceRequestFlow/config/ScreenConfigs.js'
import { CONTAINERS_BY_KEY } from 'ServiceRequestFlow/config/Containers.js'
import {
  trackFlowOpen,
  trackFlowClose,
  trackFlowCompleted,
  trackScreenFinish,
  trackScreenTransition,
  trackScreenGoBack,
} from 'ServiceRequestFlow/services/TrackingService.js'
import { withProjectSubmissionAPI } from 'ServiceRequestFlow/hoc/withProjectSubmissionAPI.js'

import './FlowNavigator.scss'

class FlowNavigator extends Component {
  constructor(props) {
    super(props)
    const {
      openOnLoad,
      defaultContainer,
      projectSubmissionAPI,
      logo,
      formVersion,
      formData,
    } = props

    let screenStack
    if (formData && formData['service_request[title]']) {
      projectSubmissionAPI.addUserInput(
        'title',
        formData['service_request[title]'],
        'initialization'
      )
      screenStack = [
        this.toStackItem(getFirstScreenKey(props)),
        this.toStackItem(
          this.toStackItem(getFirstScreenKey(props)).getNextScreenKey()
        ),
      ]
    } else {
      screenStack = [this.toStackItem(getFirstScreenKey(props))]
    }

    this.state = {
      screenStack,
      errorScreenStack: [],
      resolvedErrorScreenStack: [], // only for goBack logic
      isOpen: openOnLoad === 'true',
      container: defaultContainer || 'fullscreen',
      screenTransitionData: { prevScreenKey: null, isForwardTransition: true },
      saving: false,
      logo,
    }

    if (formVersion === 'fencing') {
      // TODO: Figure out if this is the right place for variant specific setup
      projectSubmissionAPI.addUserInput('title', 'Fencing', 'initialization')
    }

    if (this.props.variant === 'show_phone_first') {
      this.tosScreen = 'PhoneNumberScreen'
    } else {
      this.tosScreen = 'EmailScreen'
    }
  }

  componentDidMount = () => {
    this.openFlow(this.state.container)
    this.addOnClickToCtas()
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { screenStack, errorScreenStack } = this.state
    const {
      screenStack: prevScreenStack,
      errorScreenStack: prevErrorScreenStack,
    } = prevState

    const focusedScreen = last(errorScreenStack) || last(screenStack)
    const lastFocusedScreen =
      last(prevErrorScreenStack) || last(prevScreenStack)

    if (focusedScreen.key !== lastFocusedScreen.key) {
      trackScreenTransition(
        this.withTrackingData({
          fromScreen: lastFocusedScreen.key,
          toScreen: focusedScreen.key,
        })
      )
    }
  }

  render = () => {
    const {
      screenStack,
      errorScreenStack,
      resolvedErrorScreenStack,
      isOpen,
      container,
      screenTransitionData: { prevScreenKey, isForwardTransition },
    } = this.state
    const focusedScreen = last(errorScreenStack) || last(screenStack)
    const Container =
      CONTAINERS_BY_KEY[container] || CONTAINERS_BY_KEY.fullscreen

    return (
      <Container
        isOpen={isOpen}
        screenStackLength={screenStack.length}
        close={this.closeFlow}
        goBack={() => {
          if (screenStack.length > 1 && errorScreenStack.length === 0) {
            this.setState({ screenStack: screenStack.slice(0, -1) })
          } else if (
            errorScreenStack.length > 0 &&
            resolvedErrorScreenStack.length === 0
          ) {
            this.setState({
              errorScreenStack: [],
              resolvedErrorScreenStack: [],
            })
          } else if (
            errorScreenStack.length > 0 &&
            resolvedErrorScreenStack.length > 0
          ) {
            this.setState({
              resolvedErrorScreenStack: resolvedErrorScreenStack.slice(0, -1),
              errorScreenStack: [
                ...errorScreenStack,
                last(resolvedErrorScreenStack),
              ],
            })
          }

          if (screenStack.length > 1 || errorScreenStack.length > 0) {
            this.setNextScreenTransition(focusedScreen.key, false)
            trackScreenGoBack(this.withTrackingData())
          }
        }}
      >
        {pipe(
          getScreenConfig,
          mapObjIndexed((config, key) => {
            const isFocused = focusedScreen.key === key
            const wasFocused = prevScreenKey === key
            const shouldAnimate = isFocused !== wasFocused
            const errors = isFocused ? focusedScreen.errors : []
            return (
              <div
                key={key}
                className={cx('absolute h-100 w-100 dn', {
                  'z-1': config.preventGoBackAndClose,
                  db: isFocused || wasFocused,
                  'fade-up-in':
                    shouldAnimate && isForwardTransition && isFocused,
                  'fade-up-out':
                    shouldAnimate && isForwardTransition && wasFocused,
                  'fade-down-in':
                    shouldAnimate && !isForwardTransition && isFocused,
                  'fade-down-out':
                    shouldAnimate && !isForwardTransition && wasFocused,
                })}
              >
                {this.renderScreen({ ...config, key, errors, isFocused })}
              </div>
            )
          }),
          values
        )(this.props)}
      </Container>
    )
  }

  renderScreen = ({
    key,
    errors,
    isFocused,
    getScreenProps,
    getNextScreenKey,
    component: Component,
  }) => {
    const {
      projectSubmissionAPI: {
        addUserInput,
        getUserInputs,
        getServiceRequestId,
        getUser,
        getIsUserSignedIn,
        reset,
      },
      uuid,
    } = this.props

    const {
      screenStack,
      errorScreenStack,
      resolvedErrorScreenStack,
      saving,
      logo,
    } = this.state

    const configCallbackParams = {
      getUser,
      getIsUserSignedIn,
      navigatorProps: this.props,
      navigatorState: this.state,
      getUserInputs,
    }
    return (
      <Component
        saving={saving}
        errors={errors}
        isFocused={isFocused}
        recordUserInput={(k, v) => addUserInput(k, v, key)} // todo: some tracking
        logo={logo}
        close={this.closeFlow}
        getUserInputs={getUserInputs}
        uuid={uuid}
        getServiceRequestId={getServiceRequestId}
        finish={async (saveProgress = false) => {
          trackScreenFinish(this.withTrackingData({ saveProgress }))
          let nextTransitionIsForward = true

          if (
            errorScreenStack.length === 1 ||
            (saveProgress && errorScreenStack.length === 0)
          ) {
            const { errors } = await this.saveProgressAndSetErrorStack()
            if (errors.length === 0) {
              const getScreenAfterErrors = last(screenStack).getNextScreenKey
              this.goToScreen(getScreenAfterErrors(configCallbackParams))
            } else {
              nextTransitionIsForward = false
            }
          } else if (errorScreenStack.length > 1) {
            this.setState({
              errorScreenStack: errorScreenStack.slice(0, -1),
              resolvedErrorScreenStack: [
                ...resolvedErrorScreenStack,
                last(errorScreenStack),
              ],
            })
          } else {
            this.goToScreen(getNextScreenKey(configCallbackParams))
          }

          this.setNextScreenTransition(key, nextTransitionIsForward)
        }}
        reset={() => {
          reset()
          this.setState({
            screenStack: [this.toStackItem(getFirstScreenKey(this.props))],
            errorScreenStack: [],
            resolvedErrorScreenStack: [],
          })
        }}
        {...getScreenProps({
          getUserInputs,
          navigatorProps: { ...this.props, tosScreen: this.tosScreen },
        })}
      />
    )
  }

  saveProgressAndSetErrorStack = async () => {
    const {
      projectSubmissionAPI: { saveProgress, getSourceForInput },
    } = this.props

    this.setState({ saving: true })

    const { errors } = await saveProgress()
    this.setState({
      resolvedErrorScreenStack: [],
      errorScreenStack: map(
        // todo: we should collapse errors for the same source so they
        // are not put onto the error stack twice. it is fine currently,
        // they will just see the errors one at a time
        (e) => this.toStackItem(getSourceForInput(e.inputKey), e.errors),
        reverse(errors)
      ),
      saving: false,
    })

    return { errors }
  }

  goToScreen = async (key) => {
    const { screenStack } = this.state
    if (key === null) {
      const { errors } = await this.saveProgressAndSetErrorStack()
      if (errors.length === 0) this.completeFlow()
    } else {
      this.setState({ screenStack: [...screenStack, this.toStackItem(key)] })
    }
  }

  setNextScreenTransition = (prevScreenKey, isForwardTransition = true) =>
    this.setState({
      screenTransitionData: { prevScreenKey, isForwardTransition },
    })

  openFlow = (container) => {
    this.setState({ isOpen: true, container })
    if (
      this.withTrackingData().originPage.includes('www.buildzoom.com') &&
      window.parent.gtag
    ) {
      const bzGlobal = window.bzGlobal || window.parent.bzGlobal || {}
      window.parent.gtag('event', 'sr_flow', {
        action: 'opened',
        label: 'v2',
        variant: this.props.variant,
        ...(bzGlobal.baseAnalyticsParams || {}),
      })
    }
    trackFlowOpen(this.withTrackingData())
  }

  closeFlow = async () => {
    // TODO should save, but no need to set error stack; verify whether this called with completeFlow
    await this.saveProgressAndSetErrorStack()
    this.setNextScreenTransition(null, true)
    this.setState({ isOpen: false })
    // window.parent.BUILDZOOM.handleClose()
    window.parent.postMessage('close', '*')
    trackFlowClose(this.withTrackingData())
  }

  completeFlow = () => {
    const {
      projectSubmissionAPI: { getServiceRequestId },
    } = this.props

    if (
      this.withTrackingData().originPage.includes('www.buildzoom.com') &&
      window.parent.gtag
    ) {
      const bzGlobal = window.bzGlobal || window.parent.bzGlobal || {}
      window.parent.gtag('event', 'sr_flow', {
        action: 'completed',
        label: 'v2',
        variant: this.props.variant,
        ...(bzGlobal.baseAnalyticsParams || {}),
      })
    }
    trackFlowCompleted(this.withTrackingData())
    window.parent.postMessage(
      {
        action: 'finish',
        serviceRequestId: getServiceRequestId(),
      },
      '*'
    )
  }

  toStackItem = (key, errors = []) => ({
    ...getScreenConfig(this.props)[key],
    key,
    errors,
  })

  withTrackingData = (data) => {
    const {
      projectSubmissionAPI: { getServiceRequestId, getIsUserSignedIn },
      formVersion,
      uuid,
      originPage,
      variant,
      source,
      releaseVersion,
      landingPage,
      ip,
    } = this.props

    const { screenStack, errorScreenStack } = this.state
    const focusedScreen = last(errorScreenStack) || last(screenStack)
    return {
      ...data,
      target: focusedScreen.key,
      serviceRequestId: getServiceRequestId(),
      getIsUserSignedIn: getIsUserSignedIn(),
      formVersion,
      uuid,
      originPage,
      variant,
      source,
      releaseVersion,
      landingPage,
      ip,
    }
  }

  addOnClickToCtas = () =>
    map(
      (cta) =>
        cta.addEventListener('click', () =>
          this.openFlow(cta.dataset.container || this.state.container)
        ),
      document.getElementsByClassName(this.props.ctaClassName)
    )
}

export const WrappedFlowNavigator = withProjectSubmissionAPI()(FlowNavigator)
