import { Component } from 'react'
import cx from 'classnames'

import {
  prettyPhoneNumber,
  validatePhoneNumber,
} from 'ServiceRequestFlow/helpers/phoneNumber.js'
import LeftContentRightPicture from 'ServiceRequestFlow/components/screens/templates/LeftContentRightPicture.js'
import TermsOfService from 'ServiceRequestFlow/components/TermsOfService.js'
import NextButton from './buttons/NextButton.js'

import './PhoneNumberScreen.scss'
import '../TermsOfService.scss'

class PhoneNumberScreen extends Component {
  state = { phone: '', clientError: '', tracked: false }

  render = () => {
    const { saving, logo, tosScreen } = this.props
    const { phone, clientError } = this.state
    const showTos = this.constructor.name === tosScreen

    return (
      <LeftContentRightPicture
        title="What is the best number to reach you at?"
        className="phone-number-screen"
        hideImage={true}
        logo={logo}
      >
        <input
          className={cx('input w-100', { error: clientError })}
          type="tel"
          value={prettyPhoneNumber(phone)}
          onChange={({ target }) => {
            this.setState({
              phone: target.value.replace(/\D/g, '').substring(0, 10),
            })
          }}
          placeholder="Phone number"
          onKeyPress={({ key }) => {
            if (key === 'Enter' && !saving) {
              this.submit()
            }
          }}
        />
        <div className={cx('red dn mt3', { db: clientError })}>
          {clientError}
        </div>
        <NextButton className="mt4" saving={saving} onClick={this.submit} />
        {showTos && <TermsOfService version="txt" />}
      </LeftContentRightPicture>
    )
  }

  submit = () => {
    const { finish, recordUserInput } = this.props
    const { phone, tracked } = this.state

    if (validatePhoneNumber(phone)) {
      recordUserInput('phoneNumber', phone)
      if (!tracked && typeof fbq !== 'undefined') {
        this.setState({ tracked: true })
        fbq('trackCustom', 'All SR Submission with Phone')
      }
      this.setState({ clientError: '' })
      finish(true)
    } else {
      this.setState({
        clientError: '*Please enter a valid 10 digit phone number.',
      })
    }
  }
}

export default PhoneNumberScreen
