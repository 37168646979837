import { Component } from 'react'
import cx from 'classnames'
import LeftContentRightPicture from 'ServiceRequestFlow/components/screens/templates/LeftContentRightPicture.js'

import './TimingScreen.scss'

class TimingScreen extends Component {
  render = () => {
    const { finish, recordUserInput, timing, logo } = this.props

    return (
      <LeftContentRightPicture
        title="When do you need your project started?"
        className="timing-screen bg-white"
        hideImage={true}
        logo={logo}
      >
        <div
          className={cx('option pointer flex items-center', {
            selected: timing === "I'm flexible",
          })}
          onClick={() => {
            recordUserInput('timing', "I'm flexible")
            finish()
          }}
        >
          <div className="circle mh3" />
          <div>I'm Flexible</div>
        </div>
        <div
          className={cx('option pointer flex items-center', {
            selected: timing === 'As soon as possible',
          })}
          onClick={() => {
            recordUserInput('timing', 'As soon as possible')
            finish()
          }}
        >
          <div className="circle mh3" />
          <div>As soon as possible</div>
        </div>
        <div
          className={cx('option pointer flex items-center', {
            selected: timing === 'Within the next few weeks',
          })}
          onClick={() => {
            recordUserInput('timing', 'Within the next few weeks')
            finish()
          }}
        >
          <div className="circle mh3" />
          <div>Within a few weeks</div>
        </div>
        <div
          className={cx('option pointer flex items-center', {
            selected: timing === 'Within the next few months',
          })}
          onClick={() => {
            recordUserInput('timing', 'Within the next few months')
            finish()
          }}
        >
          <div className="circle mh3" />
          <div>Within a few months</div>
        </div>
      </LeftContentRightPicture>
    )
  }
}

export default TimingScreen
