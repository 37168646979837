import axios from 'axios'

const TRACKING_URL = window.location.href.includes('www.buildzoom.com')
  ? '//track.buildzoom.com/sr_event'
  : '//track.dev.buildzoom.com/sr_event'

const FILE_IMPORT_TIME_MS = Date.now()

// From: https://stackoverflow.com/questions/105034/how-to-create-guid-uuid
const generateUUID = () => {
  // Public Domain/MIT
  /* eslint no-bitwise: ["error", { "allow": ["|", "&"] }] */
  let d = new Date().getTime() // Timestamp
  let d2 =
    (window.performance &&
      window.performance.now &&
      window.performance.now() * 1000) ||
    0 // Time in microseconds since page-load or 0 if unsupported

  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let r = Math.random() * 16 // random number between 0 and 16
    if (d > 0) {
      // Use timestamp until depleted
      r = (d + r) % 16 | 0
      d = Math.floor(d / 16)
    } else {
      // Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0
      d2 = Math.floor(d2 / 16)
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
}

const track = (data) => {
  const trackingData = {
    params: {
      ...data,
      onLoadMS: FILE_IMPORT_TIME_MS,
    },
  }

  axios.get(TRACKING_URL, trackingData)

  if (process.env.NODE_ENV !== 'production') {
    /* eslint no-console: 0 */
    console.log('track', { ...trackingData })
  }
}

export const trackFlowOpen = (d) => track({ action: 'flowOpen', ...d })
export const trackFlowClose = (d) => track({ action: 'flowClose', ...d })
export const trackScreenGoBack = (d) => track({ action: 'screenGoBack', ...d })
export const trackFlowCompleted = (d) =>
  track({ action: 'flowCompleted', ...d })
export const trackScreenFinish = (d) => track({ action: 'screenFinish', ...d })
export const trackScreenTransition = (d) =>
  track({ action: 'screenTransition', ...d })
export const trackSaveProgress = (d) =>
  track({ action: 'apiSaveProgress', ...d })
export const trackThumbtackShow = (d) =>
  track({ action: 'show Thumbtack link', ...d })
export const trackThumbtackClick = (d) =>
  track({ action: 'click Thumbtack link', ...d })
export { generateUUID }

// this action gets fired when recordUserInput is called
// NOT when the user updates input
export const trackUserInput = (d) => track({ action: 'userInput', ...d })
