import cx from 'classnames'
import './NextButton.scss'

const NextButton = ({ onClick, className, saving, disabled = false }) => (
  <div
    className={cx(
      'next-button bg-bz-blue white flex items-center justify-center br2 pointer',
      className
    )}
    onClick={() => {
      if (!saving && !disabled) onClick()
    }}
    disabled={disabled}
  >
    {saving ? <div className="loader" /> : 'Next'}
  </div>
)

export default NextButton
