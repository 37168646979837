import { forEach } from 'lodash'
import { Component } from 'react'
import { path } from 'ramda'
import cx from 'classnames'
import PT from 'prop-types'
import './InputDropdown.scss'

const UP_KEYCODE = 38
const DOWN_KEYCODE = 40
const ENTER_KEYCODE = 13

class InputDropdown extends Component {
  static propTypes = {
    searchChoices: PT.arrayOf(
      PT.shape({
        label: PT.string.isRequired,
        value: PT.string,
      })
    ).isRequired,
    value: PT.string,
    placeholder: PT.string,
    onSelectResult: PT.func,
    onOutsideClickBlur: PT.func, // Triggered when the user clicks outside the input. NOT ON SELECT RESULT.
    className: PT.string,
    iconClassName: PT.string,
    scrollToInitialValue: PT.bool, // Scroll dropdown to display the initial value on focus,
    customScrollToValue: PT.string, // if 'scrollToInitialValue' is set, then scroll to custom value even if no initial value is selected
    initialValueHighlighted: PT.bool, // Highlights the first element for selection by default on dropdown
    isDisabled: PT.bool,
  }

  constructor(props) {
    super(props)
    const defaultSearchIndexValue =
      props.initialValueHighlighted === false ? -1 : 0

    this.state = {
      isEditing: false,
      defaultSearchIndexValue,
      selectedSearchIndex: defaultSearchIndexValue,

      // Used to differentiate between selecting an option blurs and clicking outside input blurs.
      wasOptionSelected: false,
    }
  }

  moveSelectedSearchUp() {
    const newIndex = this.state.selectedSearchIndex - 1
    if (newIndex < 0) {
      this.setState({ selectedSearchIndex: this.props.searchChoices.length })
    } else {
      this.setState({ selectedSearchIndex: newIndex })
    }
  }

  moveSelectedSearchDown() {
    const newIndex = this.state.selectedSearchIndex + 1
    if (newIndex > this.props.searchChoices.length) {
      this.setState({ selectedSearchIndex: this.state.defaultSearchIndexValue })
    } else {
      this.setState({ selectedSearchIndex: newIndex })
    }
  }

  componentDidMount() {
    this._eventListeners = {
      keyup: (e) => {
        if (e.keyCode === ENTER_KEYCODE) {
          const choice = path(
            ['value'],
            this.props.searchChoices[this.state.selectedSearchIndex]
          )

          this.selectOption(choice)
          e.preventDefault()
          e.stopPropagation()
        }
      },
      keydown: (e) => {
        if (e.keyCode === UP_KEYCODE) {
          this.moveSelectedSearchUp()
        } else if (e.keyCode === DOWN_KEYCODE) {
          this.moveSelectedSearchDown()
        }

        if ([UP_KEYCODE, DOWN_KEYCODE, ENTER_KEYCODE].includes(e.keyCode)) {
          // To prevent the text cursor from moving around and
          // to prevent form from submitting on enter.
          e.preventDefault()
          e.stopPropagation()
        }
      },
    }

    forEach(this._eventListeners, (eventFunc, eventType) => {
      this._inputEl.addEventListener(eventType, eventFunc)
    })
  }

  componentWillUnmount() {
    forEach(this._eventListeners, (eventFunc, eventType) => {
      this._inputEl.removeEventListener(eventType, eventFunc)
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.isEditing &&
      !prevState.isEditing &&
      this.props.scrollToInitialValue
    ) {
      if (this._selectedValueRef) {
        this._selectedValueRef.parentNode.scrollTop =
          this._selectedValueRef.offsetTop
      } else if (this._customScrollToElementRef) {
        this._customScrollToElementRef.parentNode.scrollTop =
          this._customScrollToElementRef.offsetTop
      }
    }
  }

  onFocus = () => {
    this.setState({
      isEditing: true,
      selectedSearchIndex: this.state.defaultSearchIndexValue,
    })
    if (this.props.onFocus) this.props.onFocus()
  }

  selectOption = (value) => {
    this.setState({ wasOptionSelected: true }, () => {
      this._inputEl.blur()
    })
    this.props.onSelectResult(value)
  }

  onChange(e) {
    const value = e.target.value
    this.setState({ selectedSearchIndex: this.state.defaultSearchIndexValue })

    if (this.props.onChange) {
      this.props.onChange(value)
    }
  }

  onBlur(e) {
    const { onOutsideClickBlur } = this.props
    const { wasOptionSelected } = this.state

    this.setState({ isEditing: false, wasOptionSelected: false })

    if (!wasOptionSelected && onOutsideClickBlur) {
      onOutsideClickBlur(e.target.value)
    }
  }

  render() {
    let dropdownItems
    const {
      value: displayValue,
      placeholder,
      searchChoices,
      className,
      isDisabled,
      iconClassName = 'caret',
      customScrollToValue,
    } = this.props
    const { isEditing, selectedSearchIndex } = this.state

    if (isEditing && searchChoices.length) {
      dropdownItems = (
        <div className="search-results shadow-4">
          {searchChoices.map(({ label, value }, ind) => (
            <div
              ref={(el) => {
                if (displayValue === label) {
                  this._selectedValueRef = el
                } else if (customScrollToValue === label) {
                  this._customScrollToElementRef = el
                }
              }}
              key={value}
              className={cx('u-clickable search-result', {
                selected: selectedSearchIndex === ind,
              })}
              onMouseDown={() => {
                this.selectOption(value)
              }}
            >
              {label}
            </div>
          ))}
        </div>
      )
    }

    return (
      <div className={cx('input-dropdown', className)}>
        <div className="flex justify-between items-center h-100">
          <input
            autoComplete="none"
            ref={(ref) => {
              this._inputEl = ref
            }}
            className="search-input"
            placeholder={placeholder}
            value={displayValue || ''}
            onFocus={this.onFocus.bind(this)}
            onBlur={this.onBlur.bind(this)}
            onChange={this.onChange.bind(this)}
            disabled={isDisabled}
          />
          <i className={iconClassName} />
        </div>
        {dropdownItems}
      </div>
    )
  }
}

export default InputDropdown
