import { Component } from 'react'
import { FlagProvider } from '@unleash/proxy-client-react'
import { ApolloProvider } from 'react-apollo'
import fetch from 'unfetch' // this is done to address: https://www.apollographql.com/docs/react/v2.5/recipes/2.0-migration/#fetch-polyfill
import { UnleashClassFlagProvider } from 'BoUtilities/UnleashClassFlagProvider'
import createApolloClient from 'GlobalShared/clients/createApolloClient'
import { WrappedFlowNavigator } from './components/FlowNavigator.js'

import './shared.scss'

let serverEndpoint
if (
  window.location.hostname === 'www.buildzoom.com' ||
  window.location.hostname === 'dev.buildzoom.com'
) {
  serverEndpoint = '/graphql'
} else if (process.env.NODE_ENV === 'production') {
  // UPDATE
  serverEndpoint = 'https://dev.buildzoom.com/graphql'
} else {
  serverEndpoint = 'http://local.buildzoom.com:3000/graphql'
}

const apolloClient = createApolloClient({
  httpLinkOptions: {
    uri: serverEndpoint,
    credentials: 'include',
    fetch,
  },
})

const unleashConfig = {
  url: 'https://unleash-edge.buildzoom.com/api/frontend',
  clientKey:
    window.bzGlobal?.applicationConstants?.unleash?.api_key ||
    process.env.UNLEASH_API_TOKEN,
  refreshInterval: 60,
  appName: 'srFlowV2',
}

const DEFAULT_FORM_VERSION = 'default'

class App extends Component {
  render() {
    const {
      ctaClassName,
      openOnLoad,
      defaultContainer,
      targetVersion,
      logo,
      originPage,
      source,
      ip,
      variant,
      formData,
      queryParams,
      releaseVersion,
      landingPage,
    } = this.props

    // Fencing form version is type of SR flow we want to continue to use
    const formVersion = targetVersion || DEFAULT_FORM_VERSION
    let propVariant = variant
    if (formVersion === 'fencing') {
      propVariant = formVersion
    }

    return (
      <FlagProvider config={unleashConfig}>
        <ApolloProvider client={apolloClient}>
          <UnleashClassFlagProvider
            flagName="sr_flow_v2_variants"
            render={({ getVariant, getFlagsStatus }) => {
              if (!getFlagsStatus().flagsReady) {
                return <></>
              }

              return (
                <WrappedFlowNavigator
                  ctaClassName={ctaClassName}
                  openOnLoad={openOnLoad}
                  defaultContainer={defaultContainer}
                  formVersion={formVersion}
                  logo={logo}
                  source={source}
                  originPage={originPage}
                  ip={ip}
                  variant={propVariant || getVariant().name}
                  releaseVersion={releaseVersion}
                  landingPage={landingPage}
                  formData={formData ? JSON.parse(formData) : {}}
                  queryParams={queryParams ? JSON.parse(queryParams) : {}}
                />
              )
            }}
          />
        </ApolloProvider>
      </FlagProvider>
    )
  }
}

export default App
