import { Component } from 'react'
import cx from 'classnames'

import './FinishScreen.scss'

const LOADING_ITEMS = [
  'Reviewing Project',
  'Verifying Licenses',
  'Searching Your Area',
  'Evaluating Work History',
  'Notifying Your Project Consultant',
]

class FinishScreen extends Component {
  componentDidUpdate = (prevProps) => {
    if (!prevProps.isFocused && this.props.isFocused) {
      window.setTimeout(this.props.finish, 13000)
    }
  }

  render = () => {
    return (
      <div className="finish-screen bg-bz-blue h-100 w-100">
        <div className="animation-container absolute">
          {LOADING_ITEMS.map((txt, idx) => (
            <div
              key={txt}
              className={cx(
                'row absolute top-0 white f4 cf mb4',
                `delay-${idx}`
              )}
            >
              <div className={cx('checkmark tc dib f5 h-100', `delay-${idx}`)}>
                &#10003;
              </div>
              <div className="label dib">{txt}...</div>
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export default FinishScreen
