import { Component } from 'react'
import cx from 'classnames'

import LeftContentRightPicture from 'ServiceRequestFlow/components/screens/templates/LeftContentRightPicture.js'
import NextButton from './buttons/NextButton.js'

import './PasswordScreen.scss'

class PasswordScreen extends Component {
  state = { password: '', clientError: '' }

  render = () => {
    const { saving, logo } = this.props
    const { password, clientError } = this.state

    return (
      <LeftContentRightPicture
        title="Looks like you already have an account, please enter your password."
        className="password-screen"
        hideImage={true}
        logo={logo}
      >
        <form>
          <input
            className={cx('input w-100', { error: clientError })}
            type="password"
            value={password}
            onChange={(e) => this.setState({ password: e.target.value })}
            placeholder=""
            onKeyPress={({ key }) => {
              if (key === 'Enter' && !saving) {
                this.submit()
              }
            }}
          />
          <div className={cx('red dn mt3', { db: clientError })}>
            {clientError}
          </div>
          <NextButton className="mt4" saving={saving} onClick={this.submit} />
        </form>
      </LeftContentRightPicture>
    )
  }

  submit = () => {
    const { finish, recordUserInput } = this.props
    const { password } = this.state

    if (password) {
      recordUserInput('password', password)
      this.setState({ clientError: '' })
      finish(true)
    } else {
      this.setState({ clientError: '*Please enter your password.' })
    }
  }
}

export default PasswordScreen
