import React from 'react'
import {
  useFlag,
  useUnleashClient,
  useUnleashContext,
  useVariant,
  useFlagsStatus,
} from '@unleash/proxy-client-react'

interface IUnleashClassFlagProvider {
  render: (props: unknown) => React.ReactNode
  flagName: string
}

// eslint-disable-next-line  @typescript-eslint/explicit-module-boundary-types
export const UnleashClassFlagProvider = ({
  render,
  flagName,
}: IUnleashClassFlagProvider) => {
  const enabled = useFlag(flagName)
  const variant = useVariant(flagName)
  const client = useUnleashClient()

  const updateContext = useUnleashContext()
  // eslint-disable-next-line  @typescript-eslint/no-unsafe-assignment
  const { flagsReady, flagsError } = useFlagsStatus()

  const isEnabled = () => {
    return enabled
  }

  const getVariant = () => {
    return variant
  }

  const getClient = () => {
    return client
  }

  const getUnleashContextSetter = () => {
    return updateContext
  }

  const getFlagsStatus = () => {
    // eslint-disable-next-line  @typescript-eslint/no-unsafe-assignment
    return { flagsReady, flagsError }
  }

  return (
    <>
      {render({
        isEnabled,
        getVariant,
        getClient,
        getUnleashContextSetter,
        getFlagsStatus,
      })}
    </>
  )
}
