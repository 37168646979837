import React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

Bugsnag.start({
  apiKey: 'a354b0bb63ae2be48161c071ee82f10e',
  releaseStage: process.env.NODE_ENV,
  notifyReleaseStages: ['production', 'development', 'staging'],
  plugins: [new BugsnagPluginReact()],
})

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

export default ErrorBoundary
