export const prettyPhoneNumber = (n) => {
  const onlyNumbers = n.replace(/\D/g, '')

  const firstThree = onlyNumbers.substring(0, 3)
  const secondThree = onlyNumbers.substring(3, 6)
  const lastFour = onlyNumbers.substring(6, 10)

  let pretty = ''
  if (onlyNumbers.length > 6) {
    pretty = `(${firstThree}) ${secondThree}-${lastFour}`
  } else if (onlyNumbers.length > 3) {
    pretty = `(${firstThree}) ${secondThree}`
  } else if (onlyNumbers.length > 0) {
    pretty = `(${firstThree}`
  }

  return pretty
}

export const validatePhoneNumber = (n) => n.replace(/\D/g, '').length === 10
