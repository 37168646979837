import * as filestack from 'filestack-js'

const filestackApiKey = 'ApCDun7UVTyK8WghZZrWZz'
const filestackClient = filestack.init(filestackApiKey)

export const openFilePicker = (onUploadDone) => {
  filestackClient
    .picker({
      maxFiles: 20,
      onUploadDone,
      storeTo: {
        location: 's3',
        path: '/uploads/filestack/',
      },
    })
    .open()
}
