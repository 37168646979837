import { Component } from 'react'
import cx from 'classnames'

import BackButton from 'ServiceRequestFlow/components/screens/buttons/BackButton.js'

import './FullScreenContainer.scss'

class FullScreenContainer extends Component {
  render = () => {
    const { children, isOpen, close, goBack, screenStackLength } = this.props

    return (
      <div
        className={cx(
          'full-screen-container fixed vh-100 top-0 left-0 w-100 bg-white justify-center',
          isOpen ? 'flex' : 'dn'
        )}
      >
        {children}
        <div
          className="close-button absolute black pointer"
          onClick={() => close()}
        >
          &#x2715;
        </div>
        {screenStackLength > 1 && <BackButton onClick={goBack} />}
        <div
          style={{ width: `${(screenStackLength / 11) * 100}%` }}
          className="progress-bar fixed left-0 bottom-0 bg-bz-blue dn"
        />
      </div>
    )
  }
}

export default FullScreenContainer
