import cx from 'classnames'
import { Component } from 'react'
import Fuse from 'fuse.js'
import PT from 'prop-types'
import InputDropdown from 'BoComponents/generic/vanilla/InputDropdown.js'
import './SearchableDropdown.scss'

class SearchableDropdown extends Component {
  static propTypes = {
    searchChoices: PT.arrayOf(
      PT.shape({
        label: PT.string.isRequired,
        value: PT.string,
      })
    ).isRequired,
    searchableKeys: PT.array,
    // Label for the blank value. User cannot clear the text to make it blank so this must be supplied.
    nullLabel: PT.string,
    // Override for forcing inclusion of a blank option
    excludeBlankValue: PT.bool,
    onChange: PT.func,
    onSelectResult: PT.func, // Called when an option is selected.
    onOutsideClickBlur: PT.func,
    unfocusedValue: PT.any, // Value shown when the input is unfocused
    placeholder: PT.string,
    iconClassName: PT.string,
    className: PT.string,
    disabled: PT.bool,
    disableFuzzyMatching: PT.bool,
    defaultDropdownOptions: PT.arrayOf(
      PT.shape({
        label: PT.string.isRequired,
        value: PT.string,
      })
    ),
  }

  state = { searchInput: null, isFocused: false }

  onFocus = () => {
    this.setState({
      isFocused: true,
      searchInput: '',
    })
  }

  onChange = (value) => {
    this.setState({ searchInput: value })
    if (this.props.onChange) this.props.onChange(value)
  }

  onSelectResult = (value) => {
    this.setState({ isFocused: false })
    this.props.onSelectResult(value)
  }

  onOutsideClickBlur = (value) => {
    this.setState({ isFocused: false })
    if (this.props.onOutsideClickBlur) this.props.onOutsideClickBlur(value)
  }

  render() {
    const { searchInput, isFocused } = this.state
    const { searchableKeys } = this.props

    const {
      unfocusedValue,
      searchChoices,
      placeholder,
      className,
      excludeBlankValue = false,
      nullLabel = '* Blank *',
      iconClassName,
      disabled,
    } = this.props
    let dropdownOptions = searchChoices
    if (unfocusedValue) {
      if (!excludeBlankValue) {
        dropdownOptions = [{ value: null, label: nullLabel }].concat(
          dropdownOptions
        )
      }
    }

    let value = unfocusedValue

    if (isFocused) {
      if (searchInput) {
        const fuseConfig = {
          shouldSort: true,
          keys: searchableKeys || ['label'],
        }

        if (this.props.disableFuzzyMatching) {
          fuseConfig.threshold = 0
        }

        dropdownOptions = new Fuse(searchChoices, fuseConfig).search(
          searchInput
        )
      }

      value = searchInput
    }

    if (!value && this.props.defaultDropdownOptions) {
      dropdownOptions = this.props.defaultDropdownOptions
    }

    return (
      <InputDropdown
        className={cx('searchable-dropdown', className)}
        value={value}
        searchChoices={dropdownOptions}
        onFocus={this.onFocus}
        onChange={this.onChange}
        onSelectResult={this.onSelectResult}
        onOutsideClickBlur={this.onOutsideClickBlur}
        placeholder={placeholder}
        iconClassName={iconClassName}
        isDisabled={disabled}
      />
    )
  }
}

export default SearchableDropdown
