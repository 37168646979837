import TitleScreen from 'ServiceRequestFlow/components/screens/TitleScreen.js'
import TimingScreen from 'ServiceRequestFlow/components/screens/TimingScreen.js'
import PropertyTypeScreen from 'ServiceRequestFlow/components/screens/PropertyTypeScreen.js'
import EmailScreen from 'ServiceRequestFlow/components/screens/EmailScreen.js'
import PhoneNumberScreen from 'ServiceRequestFlow/components/screens/PhoneNumberScreen.js'
import NameScreen from 'ServiceRequestFlow/components/screens/NameScreen.js'
import NumberOfBidsScreen from 'ServiceRequestFlow/components/screens/NumberOfBidsScreen.js'
import AddressScreen from 'ServiceRequestFlow/components/screens/AddressScreen.js'
import DesignReadyScreen from 'ServiceRequestFlow/components/screens/DesignReadyScreen.js'
import BudgetScreen from 'ServiceRequestFlow/components/screens/BudgetScreen.js'
import DescriptionScreen from 'ServiceRequestFlow/components/screens/DescriptionScreen.js'
import FencingDescriptionScreen from 'ServiceRequestFlow/components/screens/FencingDescriptionScreen.js'
import PasswordScreen from 'ServiceRequestFlow/components/screens/PasswordScreen.js'
import FinishScreen from 'ServiceRequestFlow/components/screens/FinishScreen.js'

import { isEmpty, path } from 'ramda'

export const getFirstScreenKey = ({ isIE, formVersion }) => {
  if (isIE) {
    return 'form'
  } else if (formVersion === 'fencing') {
    return 'name'
  } else {
    return 'title'
  }
}

export const getScreenConfig = ({ variant }) => {
  return VARIANTS[variant === 'disabled' ? 'default' : variant]
}

const FENCING_SCREENS = {
  name: {
    component: NameScreen,
    getNextScreenKey: ({ getIsUserSignedIn }) => {
      return getIsUserSignedIn() ? 'description' : 'email'
    },
    getScreenProps: () => ({}),
  },
  email: {
    component: EmailScreen,
    getNextScreenKey: () => 'description',
    getScreenProps: ({ navigatorProps: { tosScreen } }) => {
      return {
        tosScreen,
      }
    },
  },
  description: {
    component: FencingDescriptionScreen,
    getNextScreenKey: () => 'timing',
    getScreenProps: () => ({}),
  },
  timing: {
    component: TimingScreen,
    getNextScreenKey: () => 'phoneNumber',
    getScreenProps: ({ getUserInputs }) => {
      return {
        timing: path(['timing', 'value'], getUserInputs()),
      }
    },
  },
  phoneNumber: {
    component: PhoneNumberScreen,
    getNextScreenKey: () => 'address',
    getScreenProps: ({ navigatorProps: { tosScreen } }) => {
      return {
        tosScreen,
      }
    },
  },
  address: {
    component: AddressScreen,
    getNextScreenKey: () => 'finish',
    getScreenProps: () => ({}),
  },
  finish: {
    component: FinishScreen,
    getNextScreenKey: () => null,
    getScreenProps: () => ({}),
    preventGoBackAndClose: true,
  },
}

const POPULAR_PROJECTS = [
  { id: 1, name: 'Custom Home Build' },
  { id: 3, name: 'Remodel' },
  { id: 2, name: 'Home Addition' },
  { id: 5, name: 'Roofing' },
  { id: 8, name: 'Solar Installation' },
  { id: 20, name: 'Architectural Designs' },
  { id: 4, name: 'Commercial Renovation' },
  { id: 31, name: 'Commercial Construction' },
]

const PHONE_EMAIL_SCREENS = {
  title: {
    component: TitleScreen,
    getNextScreenKey: () => 'timing',
    getScreenProps: ({ navigatorProps: { formData } }) => {
      return {
        popularProjects: POPULAR_PROJECTS,
        formData,
      }
    },
  },
  timing: {
    component: TimingScreen,
    getNextScreenKey: () => 'propertyType',
    getScreenProps: ({ getUserInputs }) => {
      return {
        timing: path(['timing', 'value'], getUserInputs()),
      }
    },
  },
  propertyType: {
    component: PropertyTypeScreen,
    getNextScreenKey: ({ getUser }) => {
      const currentUser = getUser()
      if (isEmpty(currentUser) || !currentUser) {
        return 'phoneNumber'
      } else if (!currentUser.email) {
        return 'email'
      } else if (!currentUser.name) {
        return 'name'
      } else {
        return 'numberOfBids'
      }
    },
    getScreenProps: ({ getUserInputs }) => {
      return {
        propertyType: path(['propertyType', 'value'], getUserInputs()),
      }
    },
  },
  phoneNumber: {
    component: PhoneNumberScreen,
    getNextScreenKey: ({ getUser }) => {
      const currentUser = getUser()

      if (isEmpty(currentUser) || !currentUser.email) {
        return 'email'
      } else if (!currentUser.name) {
        return 'name'
      } else {
        return 'numberOfBids'
      }
    },
    getScreenProps: ({ navigatorProps: { tosScreen } }) => {
      return {
        tosScreen,
      }
    },
  },
  email: {
    component: EmailScreen,
    getNextScreenKey: ({ getUser }) => {
      const currentUser = getUser()
      if (isEmpty(currentUser) || !currentUser.name) {
        return 'name'
      } else {
        return 'numberOfBids'
      }
    },
    getScreenProps: ({ navigatorProps: { tosScreen } }) => {
      return {
        tosScreen,
      }
    },
  },
  name: {
    component: NameScreen,
    getNextScreenKey: () => 'numberOfBids',
    getScreenProps: () => ({}),
  },
  numberOfBids: {
    component: NumberOfBidsScreen,
    getNextScreenKey: () => 'address',
    getScreenProps: ({ getUserInputs }) => {
      return {
        numberOfBids: path(['numberOfBids', 'value'], getUserInputs()),
      }
    },
  },
  address: {
    component: AddressScreen,
    getNextScreenKey: () => 'designReady',
    getScreenProps: () => ({}),
  },
  designReady: {
    component: DesignReadyScreen,
    getNextScreenKey: () => 'budget',
    getScreenProps: ({ getUserInputs }) => {
      return {
        designReady: path(['designReady', 'value'], getUserInputs()),
      }
    },
  },
  budget: {
    component: BudgetScreen,
    getNextScreenKey: () => 'description',
    getScreenProps: () => ({}),
  },
  description: {
    component: DescriptionScreen,
    getNextScreenKey: () => 'finish',
    getScreenProps: () => ({}),
  },
  password: {
    component: PasswordScreen,
    getNextScreenKey: () => 'finish',
    getScreenProps: () => ({}),
  },
  finish: {
    component: FinishScreen,
    getNextScreenKey: () => null,
    getScreenProps: () => ({}),
    preventGoBackAndClose: true,
  },
}

const DEFAULT_SCREENS = {
  title: {
    component: TitleScreen,
    getNextScreenKey: () => 'timing',
    getScreenProps: ({ navigatorProps: { formData } }) => {
      return {
        popularProjects: POPULAR_PROJECTS,
        formData,
      }
    },
  },
  timing: {
    component: TimingScreen,
    getNextScreenKey: () => 'propertyType',
    getScreenProps: ({ getUserInputs }) => {
      return {
        timing: path(['timing', 'value'], getUserInputs()),
      }
    },
  },
  propertyType: {
    component: PropertyTypeScreen,
    getNextScreenKey: ({ getUser }) => {
      const currentUser = getUser()
      if (isEmpty(currentUser) || !currentUser) {
        return 'email'
      } else if (!currentUser.phoneNumber) {
        return 'phoneNumber'
      } else if (!currentUser.name) {
        return 'name'
      } else {
        return 'numberOfBids'
      }
    },
    getScreenProps: ({ getUserInputs }) => {
      return {
        propertyType: path(['propertyType', 'value'], getUserInputs()),
      }
    },
  },
  email: {
    component: EmailScreen,
    getNextScreenKey: ({ getUser }) => {
      const currentUser = getUser()

      if (
        isEmpty(currentUser) ||
        !currentUser ||
        (currentUser && !currentUser.phoneNumber)
      ) {
        return 'phoneNumber'
      } else if (!currentUser.name) {
        return 'name'
      } else {
        return 'numberOfBids'
      }
    },
    getScreenProps: ({ navigatorProps: { tosScreen } }) => {
      return {
        tosScreen,
      }
    },
  },
  phoneNumber: {
    component: PhoneNumberScreen,
    getNextScreenKey: ({ getUser }) => {
      const currentUser = getUser()
      if (isEmpty(currentUser) || !currentUser.name) {
        return 'name'
      } else {
        return 'numberOfBids'
      }
    },
    getScreenProps: ({ navigatorProps: { tosScreen } }) => {
      return {
        tosScreen,
      }
    },
  },
  name: {
    component: NameScreen,
    getNextScreenKey: () => 'numberOfBids',
    getScreenProps: () => ({}),
  },
  numberOfBids: {
    component: NumberOfBidsScreen,
    getNextScreenKey: () => 'address',
    getScreenProps: ({ getUserInputs }) => {
      return {
        numberOfBids: path(['numberOfBids', 'value'], getUserInputs()),
      }
    },
  },
  address: {
    component: AddressScreen,
    getNextScreenKey: () => 'designReady',
    getScreenProps: () => ({}),
  },
  designReady: {
    component: DesignReadyScreen,
    getNextScreenKey: () => 'budget',
    getScreenProps: ({ getUserInputs }) => {
      return {
        designReady: path(['designReady', 'value'], getUserInputs()),
      }
    },
  },
  budget: {
    component: BudgetScreen,
    getNextScreenKey: () => 'description',
    getScreenProps: () => ({}),
  },
  description: {
    component: DescriptionScreen,
    getNextScreenKey: () => 'finish',
    getScreenProps: () => ({}),
  },
  password: {
    component: PasswordScreen,
    getNextScreenKey: () => 'finish',
    getScreenProps: () => ({}),
  },
  finish: {
    component: FinishScreen,
    getNextScreenKey: () => null,
    getScreenProps: () => ({}),
    preventGoBackAndClose: true,
  },
}

const VARIANTS = {
  default: DEFAULT_SCREENS,
  show_phone_first: PHONE_EMAIL_SCREENS,
  fencing: FENCING_SCREENS,
}
